<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="420" persistent>
      <v-card>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 font-weight-medium mb-4">
              {{ $t('messages.youAreChangingYourOwnGroup') | capitalize }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-h6 mb-1"
            >
              {{ $t('messages.youMayLoseAccess') | capitalize }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-show="removingUserCompetence"
              class="text-subtitle-1 error--text mb-1 mt-1"
            >
              {{ $t('messages.youWillLoseAccessToUsers') | capitalize }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="discard"
          >
            {{ $t('actions.return') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="confirm"
            class="btn--red-bg"
          >
            {{ $t('actions.proceed') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmChangeModal extends Vue { 
  @Prop({ type: Boolean, default: false }) dialog;

  @Prop({ type: Boolean, default: false }) removingUserCompetence;

  confirm() {
    this.$emit('confirm');
  }

  discard() {
    this.$emit('discard');
  }
}
</script>

<style scoped>
.btn--red-bg {
  background-color: rgba(255, 82, 82, 0.1);
}
.v-list-item__title, .v-list-item__subtitle {
  white-space: unset;
  overflow: auto;
}
</style>
