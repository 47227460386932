var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"l-competences-tab"},[_c('div',{staticClass:"l-competencies__actions"},[_c('div',{staticClass:"l-competencies__btn"},[_c('BaseButton',{attrs:{"color":"accent","depressed":"","disabled":_vm.toggleControl || !_vm.selectedGroup},on:{"click":function($event){return _vm.preSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1),_c('div',{staticClass:"l-competencies__btn"},[_c('BaseButton',{attrs:{"color":"primary","depressed":"","disabled":_vm.toggleControl || !_vm.selectedGroup},on:{"click":function($event){return _vm.cancelCompetences()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.cancel'))+" ")])],1),_c('div',{staticClass:"l-competencies__btn"},[_c('BaseButton',{attrs:{"color":"primary","depressed":"","disabled":!_vm.toggleControl || !_vm.selectedGroup},on:{"click":function($event){return _vm.editCompetences()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.edit'))+" ")])],1)]),_c('div',{staticClass:"l-competences-tab__table"},[_c('table',{class:{ 'c-base-table': true, 'c-base-table--disabled': false }},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('main.name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('main.read')))]),_c('th',[_vm._v(_vm._s(_vm.$t('main.write')))]),_c('th',[_vm._v(_vm._s(_vm.$t('main.delete')))])])]),_c('tbody',_vm._l((_vm.groupCompetencesArray),function(competence){return _c('tr',{key:competence.type},[_c('td',[_vm._v(_vm._s(_vm.$t(("description." + (competence.name)))))]),_c('td',[(competence.type != 'circuits' && competence.type != 'groups')?_c('BaseCheckbox',{attrs:{"disabled":_vm.pending,"value":competence.read,"id":("role-checkbox-" + _vm.groupName + "-" + (competence.type) + "--read")},on:{"change":function($event){return _vm.toggleCompetence({
                permission: 'read',
                type: competence.type,
                value: $event
              })}}}):_vm._e()],1),_c('td',[(competence.type != 'analytics' && competence.type != 'historicals')?_c('BaseCheckbox',{attrs:{"disabled":_vm.pending,"value":competence.write,"id":("role-checkbox-" + _vm.groupName + "-" + (competence.type) + "--write")},on:{"change":function($event){return _vm.toggleCompetence({
                permission: 'write',
                type: competence.type,
                value: $event
              })}}}):_vm._e()],1),_c('td',[(competence.type != 'analytics' && competence.type != 'historicals')?_c('BaseCheckbox',{attrs:{"disabled":_vm.pending,"value":competence.delete,"id":("role-checkbox-" + _vm.groupName + "-" + (competence.type) + "--delete")},on:{"change":function($event){return _vm.toggleCompetence({
                permission: 'delete',
                type: competence.type,
                value: $event
              })}}}):_vm._e()],1)])}),0),(!_vm.groupCompetencesArray.length)?_c('tfoot',[_c('tr',{attrs:{"align":"center"}})]):_vm._e()])]),_c('ConfirmChangeModal',{attrs:{"dialog":_vm.dialog,"removingUserCompetence":_vm.removingUserCompetence},on:{"discard":_vm.discardSubmit,"confirm":_vm.confirmSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }